<template>
  <div>
    <RMNotLoggedInBanner/>
    <div class="row justify-content-center">
      <div
          class="col-xs-12 col-sm-10 col-md-6 col-xl-4 col-xxl-3 col-xxxl-2"
          v-for="game in gameList"
          v-bind:key="game.id"
      >
        <RMPanel :title="getTitle(game.id)">
          <template v-slot:panelBody>
            <router-link :to="getLink(game.id)">
              <img
                :src="imageLinks[game.id]"
                class="panel-image"
                width="100%"
              />
            </router-link>
          </template>
        </RMPanel>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import {isUserLoggedIn} from "@/_helpers";
import {getEntertainmentGames} from "@/_helpers/games_helper";
import {showSweetAlert} from "@/_helpers/api";
import RMPanel from "@/components/common/RMPanel.vue";
import RMNotLoggedInBanner from "@/components/common/RMNotLoggedInBanner.vue";

export default {
  name: "Entertainment",
  components: {RMNotLoggedInBanner, RMPanel},
  data() {
    return {
      imageLinks: {
        sudoku: require("@/assets/images/sudoku_01.webp"),
        caesarCypher: require("@/assets/images/caesar_cypher_01.webp"),
        minesweeper: require("@/assets/images/minesweeper_01.webp"),
        wordSearch: require("@/assets/images/word_search_01.webp"),
        simpleMaze: require("@/assets/images/simple_maze_01.webp"),
        mathCrossword: require("@/assets/images/math_crossword_01.webp"),
        memory: require("@/assets/images/memory_01.webp"),
        simpleMath: require("@/assets/images/simple_math_01.webp"),
        meme: require("@/assets/images/meme_01.webp"),
      },
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    isNewUser() {
      return (
          isUserLoggedIn() &&
          "new_user" in this.account.user &&
          this.account.user.new_user
      );
    },
    creditAutoAdded() {
      return (
          isUserLoggedIn() &&
          "credit_auto_added" in this.account.user &&
          this.account.user.credit_auto_added > 0
      );
    },
    gameList() {
      return getEntertainmentGames();
    },
  },
  methods: {
    getLink(gameId) {
      return "/games/" + gameId;
    },
    getTitle(gameId) {
      return this.$t('games.games.' + gameId + '.name')
    }
  },
  mounted() {
    if (this.isNewUser) {
      if (this.creditAutoAdded) {
        showSweetAlert("S-REGISTER-FIRST_LOGIN", this);
      }
      this.$store.commit("account/unsetNewUser");
    }
  }
};
</script>
